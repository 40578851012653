<template>
  <base-section space="2">
    <v-container>
      <base-section-title keyTranslate="redeemPrizes" />

      <section class="d-flex justify-end mt-2 mt-lg-0">
        <GoToButton keyTranslate="goToHistory" :route="getRouteGoTo" />
      </section>
    </v-container>

    <PrizesContainer />
  </base-section>
</template>

<script>
import { prizesRoutesName } from "@/router/names";

export default {
  name: "PrizesRedeemSection",
  provide: {
    heading: { align: "center" },
  },
  components: {
    GoToButton: () => import("@/components/buttons/GoToButton.vue"),
    PrizesContainer: () =>
      import("@/components/prizes/redeem/PrizesContainer.vue"),
  },
  computed: {
    getRouteGoTo() {
      return {
        name: prizesRoutesName.HISTORY.name,
      };
    },
  },
};
</script>
